@mixin mediaSm {
    @media screen and (max-width: 576px) {
        @content;
    }
}

@mixin mediaMd {
    @media screen and (max-width:768px) {
        @content;
    }
}

$maxWidth: 1440px;
$spacingXS: 5px;
$spacingS: 10px;
$spacing: 16px;
$spacingL: 25px;
$spacingXXL: 80px;
$spacingXL: 50px;
$fontSizeL: 18px;
$fontSizeXL: 24px;
$fontSizeXXL: 40px;
$fontSizeN: 14px;
$borderRadius: 15px;
$borderRadiusL: 20px;

.default-theme {
    --bg: #f4f4f4;
    --bg-grey: #f4f4f4;
    --theme-pri: #1a3590;
    --theme-sec: #52a646;
    --text-pri: #222222;
    --border: #bbbbbb;
}

@mixin title {
    color: var(--theme-pri);
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin-bottom: $spacingL;
}

// ===== Default =====
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    scroll-behavior: smooth;
}

.display-none {
    display: none !important;
}

// ====================

// ===== General =====
// inclusive
.below-sm {
    display: none !important;
}

// exclusive
.above-sm {
    display: flex !important;
}

.container {
    width: 100%;
    max-width: $maxWidth;
}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}
// ====================

// ===== Index =====
.index {
    background-color: var(--bg);
    // background-color: sandybrown;
    color: var(--text-pri);
    flex: 1;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    .header {
        // flex: 1;
        z-index: 1;
        height: 56px;
        width: 100%;
        max-width: $maxWidth;
        position: fixed;
        top: 0;
        background-color: var(--bg);
        // background-color: salmon;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 $spacing;
        justify-content: space-between;

        .container-logo {
            display: flex;
            flex-direction: row;
            align-items: center;

            .logo {
                img {
                    width: 100%;
                }
            }

            .text {
                margin-left: $spacingS;
                color: var(--text-pri);
                font-size: $fontSizeL;
                font-weight: bold;
            }
        }

        .nav {
            // margin-left: 100px;

            a {
                color: var(--text-pri);
            }

            .nav-item {
                margin-right: $spacing;
                padding: 5px 10px;
                border-radius: 15px;
                cursor: pointer;
                font-weight: 700;

                

                &:hover {
                    background-color: var(--theme-pri);
                    color: var(--bg);
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .footer {
        // background-color: salmon;
        border-top: solid 1px var(--border);
        flex: 1;
        height: 56px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 $spacing;

        .social {
            display: flex;
            flex-direction: row;
            align-items: center;

            .container-icon {
                margin-left: $spacing;

                .item {
                    margin-right: $spacing;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .company {
        flex: 1;
        height: 95vh;
        // background-color: orange;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 $spacing;

        .container-bannerimg {
            img {
                width: 100%;
            }
        }

        .column {
            align-items: center;

            .row {
                .logo {
                    width: 550px;
        
                    img {
                        width: 100%;
                    }
                }
            }

            .tagline {
                font-size: 22px;
                margin-top: $spacing;
            }

            .why {
                font-size: 20px;
                font-weight: 600;
            }
        }

    }

    .about {
        padding: $spacingXXL $spacing;

        .title {
            @include title();
        }

        .column {
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: center;

            .item {
                // background-color: salmon;
                margin-bottom: $spacingL;
                display: flex;
                flex-direction: column;
                width: 70%;

                .item-title {
                    margin: $spacing 0;
                    font-size: $fontSizeXL;
                    font-weight: 600;
                    // text-align: center;
                }

                .item-subtitle {
                    font-size: $fontSizeL;
                    // text-align: center;
                }
            }
        }
    }

    .services {
        padding: $spacingXXL $spacing;

        .title {
            @include title();
        }

        .row {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: $spacingXXL;

            &:last-child {
                margin-bottom: 0;
            }

            .item {
                margin-right: $spacingL;
                // background-color: salmon;
                width: 33%;
                // flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;

                &:last-child {
                    margin-right: 0;
                }

                .image {
                    border-radius: $borderRadius;
                    overflow: hidden;
                    width: 100%;

                    img {
                        width: 100%;
                    }
                }

                .item-title {
                    margin: $spacing 0;
                    font-size: $fontSizeXL;
                    font-weight: 600;
                    text-align: center;
                }

                .item-subtitle {
                    font-size: $fontSizeL;
                    text-align: center;
                }
            }
        }
    }

    .clients {
        padding: $spacingXXL $spacing;

        .title {
            @include title();
        }

        .row {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: $spacingXXL;

            &:last-child {
                margin-bottom: 0;
            }

            .item {
                margin-right: $spacingL;
                // background-color: salmon;
                // width: 33%;
                width: 22%;
                // flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;

                &:last-child {
                    margin-right: 0;
                }

                .image {
                    border-radius: $borderRadius;
                    overflow: hidden;
                    width: 100%;

                    img {
                        width: 100%;
                    }
                }

                .item-title {
                    margin: $spacing 0;
                    font-size: $fontSizeXL;
                    font-weight: 600;
                    text-align: center;
                }

                .item-subtitle {
                    font-size: $fontSizeL;
                    text-align: center;
                }
            }
        }

        .container-text {
            margin: auto;
            width: 70%;
            font-size: $fontSizeL;
            margin-bottom: $spacingXL;
        }
    }

    .testimonials {
        padding: $spacingXXL $spacing;

        .title {
            @include title();
        }

        .row {
            display: flex;
            flex-direction: row;
            justify-content: center;

            .item {
                margin-right: $spacingL;
                width: 33%;
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: #ffffff;
                padding: $spacingL;
                border-radius: $borderRadius;

                .image {
                    border-radius: 50%;
                    border: 2px solid var(--theme-pri);
                    overflow: hidden;
                    width: 100%;
                    max-width: 150px;
                    max-height: 150px;
                    margin-bottom: $spacingL;

                    img {
                        width: 100%;
                    }
                }

                .quote {
                    font-size: $fontSizeL;
                    margin-bottom: $spacing;

                    .inverted-comma {
                        font-size: $fontSizeXXL;
                        line-height: $fontSizeL;
                        font-weight: bold;
                        color: var(--theme-sec);
                    }
                }

                .endorser {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .item-title {
                        font-size: $fontSizeXL;
                        font-weight: bold;
                        color: var(--theme-sec);
                    }

                    .item-subtitle {
                        font-size: $fontSizeL;
                    }
                }
            }
        }
    }

    .contact {
        padding: $spacingXXL $spacing;

        .title {
            @include title();
        }

        .row {
            display: flex;
            flex-direction: row;
            justify-content: center;

            .item {
                margin-right: $spacingL;
                // background-color: salmon;
                width: 45%;
                display: flex;
                flex-direction: column;
                padding: $spacing;
                border-radius: $borderRadius;
                border: solid 1px var(--border);

                &:last-child {
                    margin-right: 0;
                }

                .item-title {
                    margin: 0 0 $spacing;
                    font-size: $fontSizeXL;
                    font-weight: 600;
                }

                .item-subtitle {
                    font-size: $fontSizeL;

                    .highlight {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
// ====================

//  ===== Tablets and Small Laptops =====
@include mediaMd {
    .index {
        .clients .row, .services .row, .contact .row, .testimonials .row {
            flex-direction: column;
            margin-bottom: 0;

            .item {
                margin-right: 0;
                margin-bottom: $spacingXXL;
            }
        }

        .clients .row .item, .services .row .item, .contact .row .item, .testimonials .row .item {
            width: 100%;
        }

        .company {
            .container-bannerimg {
                background-color: salmon;
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                img {
                    position: absolute;
                    width: auto;
                    max-width: 750px;
                    right: 0;
                }
            }
        }
    }

}
// ====================

// ===== Smartphones =====
@include mediaSm {
    .below-sm {
        display: flex !important;
    }

    .above-sm {
        display: none !important;
      }
}
// ====================
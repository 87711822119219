.default-theme {
  --bg: #f4f4f4;
  --bg-grey: #f4f4f4;
  --theme-pri: #1a3590;
  --theme-sec: #52a646;
  --text-pri: #222222;
  --border: #bbbbbb;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

.display-none {
  display: none !important;
}

.below-sm {
  display: none !important;
}

.above-sm {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.container {
  width: 100%;
  max-width: 1440px;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.index {
  background-color: var(--bg);
  color: var(--text-pri);
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
}

.index .header {
  z-index: 1;
  height: 56px;
  width: 100%;
  max-width: 1440px;
  position: fixed;
  top: 0;
  background-color: var(--bg);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 16px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.index .header .container-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.index .header .container-logo .logo img {
  width: 100%;
}

.index .header .container-logo .text {
  margin-left: 10px;
  color: var(--text-pri);
  font-size: 18px;
  font-weight: bold;
}

.index .header .nav a {
  color: var(--text-pri);
}

.index .header .nav .nav-item {
  margin-right: 16px;
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;
  font-weight: 700;
}

.index .header .nav .nav-item:hover {
  background-color: var(--theme-pri);
  color: var(--bg);
}

.index .header .nav .nav-item:last-child {
  margin-right: 0;
}

.index .footer {
  border-top: solid 1px var(--border);
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 56px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 16px;
}

.index .footer .social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.index .footer .social .container-icon {
  margin-left: 16px;
}

.index .footer .social .container-icon .item {
  margin-right: 16px;
}

.index .footer .social .container-icon .item:last-child {
  margin-right: 0;
}

.index .company {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 95vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  padding: 0 16px;
}

.index .company .container-bannerimg img {
  width: 100%;
}

.index .company .column {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.index .company .column .row .logo {
  width: 550px;
}

.index .company .column .row .logo img {
  width: 100%;
}

.index .company .column .tagline {
  font-size: 22px;
  margin-top: 16px;
}

.index .company .column .why {
  font-size: 20px;
  font-weight: 600;
}

.index .about {
  padding: 80px 16px;
}

.index .about .title {
  color: var(--theme-pri);
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 25px;
}

.index .about .column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.index .about .column .item {
  margin-bottom: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 70%;
}

.index .about .column .item .item-title {
  margin: 16px 0;
  font-size: 24px;
  font-weight: 600;
}

.index .about .column .item .item-subtitle {
  font-size: 18px;
}

.index .services {
  padding: 80px 16px;
}

.index .services .title {
  color: var(--theme-pri);
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 25px;
}

.index .services .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 80px;
}

.index .services .row:last-child {
  margin-bottom: 0;
}

.index .services .row .item {
  margin-right: 25px;
  width: 33%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.index .services .row .item:last-child {
  margin-right: 0;
}

.index .services .row .item .image {
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
}

.index .services .row .item .image img {
  width: 100%;
}

.index .services .row .item .item-title {
  margin: 16px 0;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.index .services .row .item .item-subtitle {
  font-size: 18px;
  text-align: center;
}

.index .clients {
  padding: 80px 16px;
}

.index .clients .title {
  color: var(--theme-pri);
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 25px;
}

.index .clients .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 80px;
}

.index .clients .row:last-child {
  margin-bottom: 0;
}

.index .clients .row .item {
  margin-right: 25px;
  width: 22%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.index .clients .row .item:last-child {
  margin-right: 0;
}

.index .clients .row .item .image {
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
}

.index .clients .row .item .image img {
  width: 100%;
}

.index .clients .row .item .item-title {
  margin: 16px 0;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.index .clients .row .item .item-subtitle {
  font-size: 18px;
  text-align: center;
}

.index .clients .container-text {
  margin: auto;
  width: 70%;
  font-size: 18px;
  margin-bottom: 50px;
}

.index .testimonials {
  padding: 80px 16px;
}

.index .testimonials .title {
  color: var(--theme-pri);
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 25px;
}

.index .testimonials .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.index .testimonials .row .item {
  margin-right: 25px;
  width: 33%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #ffffff;
  padding: 25px;
  border-radius: 15px;
}

.index .testimonials .row .item .image {
  border-radius: 50%;
  border: 2px solid var(--theme-pri);
  overflow: hidden;
  width: 100%;
  max-width: 150px;
  max-height: 150px;
  margin-bottom: 25px;
}

.index .testimonials .row .item .image img {
  width: 100%;
}

.index .testimonials .row .item .quote {
  font-size: 18px;
  margin-bottom: 16px;
}

.index .testimonials .row .item .quote .inverted-comma {
  font-size: 40px;
  line-height: 18px;
  font-weight: bold;
  color: var(--theme-sec);
}

.index .testimonials .row .item .endorser {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.index .testimonials .row .item .endorser .item-title {
  font-size: 24px;
  font-weight: bold;
  color: var(--theme-sec);
}

.index .testimonials .row .item .endorser .item-subtitle {
  font-size: 18px;
}

.index .contact {
  padding: 80px 16px;
}

.index .contact .title {
  color: var(--theme-pri);
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 25px;
}

.index .contact .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.index .contact .row .item {
  margin-right: 25px;
  width: 45%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 16px;
  border-radius: 15px;
  border: solid 1px var(--border);
}

.index .contact .row .item:last-child {
  margin-right: 0;
}

.index .contact .row .item .item-title {
  margin: 0 0 16px;
  font-size: 24px;
  font-weight: 600;
}

.index .contact .row .item .item-subtitle {
  font-size: 18px;
}

.index .contact .row .item .item-subtitle .highlight {
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .index .clients .row, .index .services .row, .index .contact .row, .index .testimonials .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 0;
  }
  .index .clients .row .item, .index .services .row .item, .index .contact .row .item, .index .testimonials .row .item {
    margin-right: 0;
    margin-bottom: 80px;
  }
  .index .clients .row .item, .index .services .row .item, .index .contact .row .item, .index .testimonials .row .item {
    width: 100%;
  }
  .index .company .container-bannerimg {
    background-color: salmon;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .index .company .container-bannerimg img {
    position: absolute;
    width: auto;
    max-width: 750px;
    right: 0;
  }
}

@media screen and (max-width: 576px) {
  .below-sm {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .above-sm {
    display: none !important;
  }
}
